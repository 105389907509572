import React, { ReactElement, ReactNode } from "react";

interface Props {
	sectionId: number;
	children: ReactNode;
	snapBottom?: boolean;
	autoHeight?: boolean;
	smallHeight?: boolean;
}

const PageSection = ({ sectionId, children, snapBottom, autoHeight, smallHeight }: Props): ReactElement => {
	let classList = "mx-auto flex w-screen items-center justify-center text-white page-section";
	classList += snapBottom ? " snap-end" : " snap-start";
	classList += autoHeight ? " min-h-screen" : smallHeight ? "" : " h-screen";

	return (
		<section className={classList} id={"section-" + sectionId}>
			{children}
		</section>
	);
};

export default PageSection;

import React, { ReactElement, ReactNode } from "react";

interface Props {
	href: string;
	children: ReactNode;
	className?: string;
}

const Link = ({ href, children, className }: Props): ReactElement => (
	<a className={"text-2xs font-sen hover:scale-105 " + className} href={href}>
		{children}
	</a>
);

export default Link;

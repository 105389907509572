import React, { ReactElement, ReactNode } from "react";

interface Props {
	children: ReactNode;
	className?: string;
}

const Overline = ({ children, className }: Props): ReactElement => {
	return <p className={"font-sen text-2xs font-bold uppercase " + className}>{children}</p>;
};

export default Overline;

export const Languages = new Map<string, string>([
	["English", "EN"],
	["Slovak", "SK"],
	["French", "FR"],
	["Russian", "RU"],
	["Arabic", "AR"],
]);

export interface VisibleSection {
	id: number;
	origin: SectionChangeOrigin;
}

export enum SectionChangeOrigin {
	Scroll,
	NavBar,
}

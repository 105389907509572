import React, { ReactElement } from "react";

interface Props {
	title: string;
	className?: string;
}

const Heading2 = ({ title, className }: Props): ReactElement => {
	return <h2 className={"text-2xl font-bold " + className}>{title}</h2>;
};

export default Heading2;

import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import Heading1 from "../components/Heading1";
import Overline from "../components/Overline";
import Paragraph1 from "../components/Paragraph1";
import PageSection from "./PageSection";


// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import imageMedicineCover from "../assets/images/medicine-cover.webp";
import Anchor from "../components/Anchor";

const Medicine = (): ReactElement => {
	const { t } = useTranslation();

	return (
		<div className="h-screen overflow-x-hidden overflow-y-scroll font-playfair">
			<PageSection autoHeight sectionId={4}>
				<div className="image-marker flex h-full min-h-screen w-full flex-col items-center bg-black pt-[106px] text-sand lg:pt-[186px]">
					<div className="container mb-[88px] flex h-full flex-col items-center">
						<Heading1 className="mb-[32px] block w-full text-left" title={t("CONTENT.MEDICINEMODAL.HEADING")} />
						<Paragraph1 className="mb-[24px] text-justify">{t("CONTENT.MEDICINEMODAL.P1")}</Paragraph1>
						<Paragraph1 className="mb-[24px] block w-full text-left text-gold">
							{t("CONTENT.MEDICINEMODAL.H2")}
						</Paragraph1>
						<Paragraph1 className="mb-[24px] text-justify">{t("CONTENT.MEDICINEMODAL.P2")}</Paragraph1>
						<Paragraph1 className="mb-[24px] block w-full text-left text-gold">
							{t("CONTENT.MEDICINEMODAL.H3")}
						</Paragraph1>
						<Paragraph1 className="mb-[24px] text-justify">{t("CONTENT.MEDICINEMODAL.P31")}</Paragraph1>
						<Paragraph1 className="mb-[24px] text-justify">{t("CONTENT.MEDICINEMODAL.P32")}</Paragraph1>
						<Paragraph1 className="mb-[59px] text-justify">{t("CONTENT.MEDICINEMODAL.P33")}</Paragraph1>
						<Overline className="text-center">{t("CONTENT.MEDICINEMODAL.CONCLUSION")}</Overline>
					</div>
					<Anchor
						href="/"
						className="icon-back mx-auto inline-block h-[80px] w-[80px] cursor-pointer select-none transition hover:scale-105"
					>
						<></>
					</Anchor>
					<div className="align-center flex flex-1">
						<img width="100%" className="object-contain" src={imageMedicineCover} alt="Gold nugget" />
					</div>
				</div>
			</PageSection>
		</div>
	);
};

export default Medicine;

import React, { ReactElement } from "react";
import Paragraph1 from "./Paragraph1";

interface Props {
	text: string;
}

const GridItem = ({ text }: Props): ReactElement => {
	return (
		<div className="grid-item flex flex-col items-center">
			<i className="image-logo-small w-[40px] h-[40px] inline-block mb-[24px]" />
			<Paragraph1 className="font-normal text-center">{text}</Paragraph1>
		</div>
	);
};

export default GridItem;

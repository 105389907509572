import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import GridItem from "./components/GridItem";
import Heading2 from "./components/Heading2";
import Heading3 from "./components/Heading3";
import Overline from "./components/Overline";
import Paragraph1 from "./components/Paragraph1";
import Paragraph2 from "./components/Paragraph2";
import Loading from "./containers/Loading";
import NavBar from "./containers/NavBar";
import PageSection from "./containers/PageSection";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import imageMariam from "./assets/images/mariam.webp";
import Link from "./components/Link";
import { SectionChangeOrigin, VisibleSection } from "./types";
import smoothscroll from "smoothscroll-polyfill";
import Anchor from "./components/Anchor";

const App = (): ReactElement => {
	const [isIframeLoaded, setIframeLoaded] = useState(false);
	const [visibleSection, setVisibleSection] = useState<VisibleSection>({
		id: 0,
		origin: SectionChangeOrigin.Scroll,
	});

	const pageSections = document.querySelectorAll(".page-section") as NodeListOf<HTMLElement>;
	const animateElements = document.querySelectorAll(".animate-element") as NodeListOf<HTMLElement>;
	// let previousScroll = 0;

	const { t } = useTranslation();

	useEffect(() => {
		smoothscroll.polyfill();
	}, []);

	const onVideoLoadEnd = (): void => {
		setTimeout(() => {
			setIframeLoaded(true);
		}, 400);
	};

	useEffect(() => {
		if (visibleSection.origin === SectionChangeOrigin.NavBar) {
			const section = document.getElementById(`section-${visibleSection.id}`);
			if (section) {
				section.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}
		}
	}, [visibleSection.id]);

	const onScroll = (): void => {
		for (let i = 0; i < pageSections.length; i++) {
			const section = pageSections[i];
			const rect = section.getBoundingClientRect();
			if (rect.top < window.innerHeight / 2 && rect.top >= 0 && visibleSection.id !== i) {
				setVisibleSection({
					id: i,
					origin: SectionChangeOrigin.Scroll,
				});
			}
		}
		for (let i = 0; i < animateElements.length; i++) {
			const element = animateElements[i];
			const rect = element.getBoundingClientRect();
			if (Math.abs(rect.top) <= window.screen.height - 200 && !element.classList.contains("animate")) {
				element.classList.add("animate");
			} else if (Math.abs(rect.top) > window.screen.height - 200 && element.classList.contains("animate")) {
				element.classList.remove("animate");
			}
		}
	};

	return (
		<div
			className="h-screen overflow-x-hidden overflow-y-scroll font-playfair"
			id="scroll-snap-container"
			onScroll={onScroll}
		>
			<Loading isVisible={!isIframeLoaded} />
			<NavBar
				visibleSectionId={visibleSection.id}
				setVisibleSectionId={(id: number) =>
					setVisibleSection({
						id,
						origin: SectionChangeOrigin.NavBar,
					})
				}
			/>
			<PageSection sectionId={0}>
				<div className="relative -z-30 flex h-full w-full items-center justify-center bg-black">
					<video onPlay={onVideoLoadEnd} autoPlay loop playsInline muted>
						{window.innerWidth > 768 ? (
							<source src="/video-desktop.mp4" type="video/mp4" />
						) : (
							<source src="/video-mobile.mp4" type="video/mp4" />
						)}
					</video>
					<div className="container absolute left-auto top-0 flex h-full w-full flex-col items-center justify-center">
						<i className="main-logo mb-[37px] inline-block h-[120px] w-[120px] md:h-big-logo md:w-big-logo select-none md:mb-[54px]" />
						<Heading2 className="mb-[40px] text-center sm:mb-[54px]" title={t("HERO.HEADING")} />
						<Paragraph1 className="upprecase text-center">{t("HERO.SUBHEADING")}</Paragraph1>
					</div>
				</div>
			</PageSection>
			<PageSection sectionId={1} autoHeight>
				<div className="relative flex h-full min-h-screen w-full flex-col items-center overflow-hidden bg-white pt-[106px] text-text lg:pt-[146px]">
					<div className="container mb-[20px] flex flex-col items-start lg:mb-0">
						<Heading2 title={t("CONTENT.SOLUTIONS.HEADING")} className="mb-4 text-left sm:mb-6" />
						<Paragraph2 className="mb-4 text-justify sm:mb-6">{t("CONTENT.SOLUTIONS.P1")}</Paragraph2>
						<Paragraph2 className="text-justify">{t("CONTENT.SOLUTIONS.P2")}</Paragraph2>
					</div>
					<div className="relative min-h-[300px] w-full flex-1">
						<i className="stone-left animate-element absolute right-0 top-0 h-full w-full" />
						<i className="stone-right animate-element absolute right-0 top-0 h-full" />
					</div>
				</div>
			</PageSection>
			<PageSection autoHeight sectionId={2}>
				<div className="relative -z-20 flex h-full min-h-screen w-full items-center justify-center bg-sand pt-[106px] pb-[100px] text-text lg:pt-[50px] lg:pb-0">
					<div className="animate-element animation-circles absolute left-0 top-0 -z-20 h-full w-full">
						<span className="animation-circle-2" />
					</div>
					<div className="container flex h-full w-full flex-col items-center lg:flex-row lg:justify-between">
						<div className="mb-[70px] lg:max-w-[366px] ">
							<Heading2
								title={t("CONTENT.ELITEMETHOD.HEADING")}
								className="mb-[40px] text-left text-gold lg:mb-[62px]"
							/>
							<Paragraph1 className="text-justify">{t("CONTENT.ELITEMETHOD.P1")}</Paragraph1>
						</div>
						<div className="lg:max-w-[366px]">
							<Heading2
								title={t("CONTENT.UNIQUEASATREASURE.HEADING")}
								className="mb-[40px] text-left text-gold lg:mb-[62px]"
							/>
							<Paragraph1 className="text-justify">{t("CONTENT.UNIQUEASATREASURE.P1")}</Paragraph1>
						</div>
					</div>
				</div>
			</PageSection>
			<PageSection autoHeight sectionId={3}>
				<div className="flex h-full min-h-screen w-full flex-col items-center justify-center bg-white  pt-[106px] text-text lg:pt-[50px]">
					<div className="flex h-full w-screen flex-col content-between lg:container lg:flex-row">
						<div className="mb-[60px] h-full flex-1 px-[25px] lg:ml-auto lg:mr-[20%] lg:mb-0 lg:px-0">
							<Heading2 className="mb-6" title={t("CONTENT.MARIAM.HEADING")} />
							<Paragraph1 className="mb-3 text-justify">{t("CONTENT.MARIAM.P1")}</Paragraph1>
							<Paragraph1 className="text-justify">{t("CONTENT.MARIAM.P2")}</Paragraph1>
						</div>
						<div className="align-center flex flex-1">
							<img width="100%" className="object-contain" src={imageMariam} alt="Siti Mariam Lareine" />
						</div>
					</div>
				</div>
			</PageSection>
			<PageSection autoHeight sectionId={4}>
				<div className="flex h-full min-h-screen w-full flex-col items-center justify-center bg-black text-san ">
					<div className="container flex h-full flex-col items-center justify-center pb-[106px] sm:pb-0 pt-[106px]">
						<Overline className="mb-[32px] text-center text-gold">{t("CONTENT.MEDICINE.INTRO")}</Overline>
						<Heading3 className="mb-[24px]" title={t("CONTENT.MEDICINE.HEADING")} />
						<Paragraph2 className="pb-[16px] text-justify">{t("CONTENT.MEDICINE.P1")}</Paragraph2>
						<Paragraph2 className="pb-[99px] text-justify">{t("CONTENT.MEDICINE.P2")}</Paragraph2>
						<Anchor
							className="mb-[50px] cursor-pointer select-none border-b border-gold pb-[5px] text-gold opacity-70 transition hover:scale-105 animation-padding"
							href="/medicine"
						>
							{t("CONTENT.MEDICINE.BUTTON")}
						</Anchor>
					</div>
				</div>
			</PageSection>
			<PageSection autoHeight sectionId={5}>
				<div className="image-marker animate-element flex h-full min-h-screen w-full flex-col items-center bg-black pt-[166px] text-sand">
					<div className="container flex flex-col items-center pb-[220px] sm:pb-[80px]">
						<Heading2 className="mb-[50px] sm:mb-[40px] text-center" title={t("CONTENT.TREATMENT.HEADING")} />
						<div className="grid grid-cols-1 gap-y-[40px] gap-x-[30px] sm:grid-cols-2 md:grid-cols-3">
							<GridItem text={t("CONTENT.TREATMENT.P1")} />
							<GridItem text={t("CONTENT.TREATMENT.P2")} />
							<GridItem text={t("CONTENT.TREATMENT.P3")} />
							<GridItem text={t("CONTENT.TREATMENT.P4")} />
							<GridItem text={t("CONTENT.TREATMENT.P5")} />
							<GridItem text={t("CONTENT.TREATMENT.P6")} />
							<GridItem text={t("CONTENT.TREATMENT.P7")} />
							<GridItem text={t("CONTENT.TREATMENT.P8")} />
							<GridItem text={t("CONTENT.TREATMENT.P9")} />
						</div>
					</div>
				</div>
			</PageSection>
			<PageSection sectionId={6}>
				<div className="image-logo-white animate-element flex h-full w-full flex-col items-center bg-sand text-text">
					<div className="container flex h-full flex-col items-center justify-center">
						<Heading2 className="mb-[24px] text-gold" title={t("CONTENT.LETSTALK.HEADING")} />
						<Paragraph1 className="mb-[16px]">{t("CONTENT.LETSTALK.PHONE")}</Paragraph1>
						<Link className="mb-[16px] underline" href="tel:+421918301821">
							+421 918 301 821
						</Link>
						<Link className="mb-[16px] underline" href="tel:+33786014312">
							+33 7 8601 4312
						</Link>
						{/* <Paragraph1 className="mb-[16px]">{t("CONTENT.LETSTALK.EMAIL")}</Paragraph1>
						<Link className="mb-[16px] underline" href="mailto:mariam@lareine.global">
							mariam@lareine.global
						</Link> */}
					</div>
				</div>
			</PageSection>
			<PageSection snapBottom smallHeight sectionId={7}>
				<div className="flex w-full relative h-auto flex-col items-center bg-white pt-[60px] pb-[58px] text-text sm:pb-[80px]">
					<div className="container flex flex-col items-center">
						<i className="image-dark-logo mb-[71px] inline-block h-[100px] w-[100px]" />
						<Paragraph2 className="mb-[16px] text-lightgrey">© Copyright 2024 mariamlareine.com</Paragraph2>
						<Paragraph2 className="text-lightgrey">All rights reserved.</Paragraph2>
					</div>
				</div>
			</PageSection>
		</div>
	);
};

export default App;

import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../components/LanguageSelect";
import NavBarItem from "../components/NavBarItem";

interface Props {
	visibleSectionId: number;
	setVisibleSectionId: (id: number) => void;
}

const NavBar = ({ visibleSectionId, setVisibleSectionId }: Props): ReactElement => {
	const [isMobileVisible, setMobileVisible] = useState(false);
	const { t } = useTranslation();

	const onItemClick = useCallback(
		(id: number) => {
			setVisibleSectionId(id);
			if (isMobileVisible) {
				setMobileVisible(false);
			}
		},
		[isMobileVisible]
	);

	const onMobileExpandClick = (): void => {
		setMobileVisible(!isMobileVisible);
	};

	return (
		<>
			<nav
				id="navbar"
				className={
					"absolute z-20 flex h-[76px] w-screen flex-row items-center justify-end transition lg:hidden " +
					(isMobileVisible ? "bg-transparent" : "bg-black/30")
				}
			>
				<i
					className={
						"icon-expand select-none inline-block h-[76px] w-[76px] cursor-pointer hover:scale-105 " +
						(isMobileVisible ? "icon-animated" : "")
					}
					onClick={onMobileExpandClick}
				>
					<div>
						<span />
						<span />
					</div>
				</i>
			</nav>
			<nav
				id="navbar-large"
				className={
					"absolute z-10 flex h-screen w-screen flex-col items-center justify-center bg-dark transition lg:h-[88px] lg:flex-row lg:bg-black/30 lg:px-28 " +
					(isMobileVisible
						? "opacity-1 pointer-events-auto translate-y-0"
						: "pointer-events-none -translate-y-5 opacity-0 lg:pointer-events-auto lg:translate-y-0 lg:opacity-100")
				}
			>
				<LanguageSelect
					isMobileVisible={isMobileVisible}
					setMobileVisible={(visible: boolean) => setMobileVisible(visible)}
				/>
				<div className="mb-[15px] flex w-full flex-col items-center justify-center lg:mb-0 lg:h-full lg:flex-1 lg:flex-row">
					<NavBarItem text={t("NAV.SOLUTIONS")} onClick={() => onItemClick(1)} isSelected={visibleSectionId === 1} />
					<NavBarItem text={t("NAV.ELITEMETHOD")} onClick={() => onItemClick(2)} isSelected={visibleSectionId === 2} />
					<NavBarItem text={t("NAV.SESSIONS")} onClick={() => onItemClick(3)} isSelected={visibleSectionId === 3} />
					<NavBarItem text={t("NAV.MEDICINE")} onClick={() => onItemClick(4)} isSelected={visibleSectionId === 4} />
					<NavBarItem
						text={t("NAV.TREATMENTOPTIONS")}
						onClick={() => onItemClick(5)}
						isSelected={visibleSectionId === 5}
					/>
					<NavBarItem text={t("NAV.CONTACT")} onClick={() => onItemClick(6)} isSelected={visibleSectionId === 6} />
				</div>
			</nav>
		</>
	);
};

export default NavBar;

import React ,{ ReactElement } from "react";
import Overline from "./Overline";

interface Props {
	title: string;
	onClick: () => void;
	isSelected: boolean;
}

const LanguageSelectOption = ({ title, onClick, isSelected }: Props): ReactElement => {
	return (
		<div className={"pb-[12px] hover:underline decoration-2 " + (isSelected && "text-gold")} onClick={onClick}>
			<Overline className="text-left">{title}</Overline>
		</div>
	);
};

export default LanguageSelectOption;

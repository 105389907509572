import React, { ReactElement } from "react";

interface Props {
	text: string;
	isSelected: boolean;
	onClick: () => void;
}

const NavBarItem = ({ text, isSelected, onClick }: Props): ReactElement => {
	return (
		<div
			onClick={onClick}
			className={
				"flex h-[88px] w-full cursor-pointer select-none items-center justify-center px-[24px] text-center font-sen text-[16px] font-bold uppercase text-white/70 decoration-2 transition hover:underline lg:h-full lg:w-auto " +
				(isSelected && "bg-gold text-white")
			}
		>
			{text}
		</div>
	);
};

export default NavBarItem;

import React, { ReactElement, ReactNode } from "react";

interface Props {
	children: ReactNode;
	className?: string;
	href: string;
}

const Anchor = ({ children, className, href }: Props): ReactElement => {
	return (
		<a href={href} className={"font-sen text-2xs font-normal " + className}>
			{children}
		</a>
	);
};

export default Anchor;

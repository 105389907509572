import React, { ReactElement } from "react";

interface Props {
	title: string;
	className?: string;
}

const Heading3 = ({ title, className }: Props): ReactElement => {
	return <h3 className={"text-center text-xl font-bold " + className}>{title}</h3>;
};

export default Heading3;

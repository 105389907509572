import React from "react";
import ReactDOM from "react-dom";

import "./i18n";
import "./styles/global.scss";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Medicine from "./containers/Medicine";

ReactDOM.render(
	<React.StrictMode>
		<React.Suspense fallback={<></>}>
			<Router>
				<Routes>
					<Route path="/" element={<App />} />
					<Route path="/medicine" element={<Medicine />} />
				</Routes>
			</Router>
		</React.Suspense>
	</React.StrictMode>,
	document.querySelector("#root")
);

reportWebVitals();

import React, { ReactElement } from "react";

interface Props {
	title: string;
	className?: string;
}

const Heading1 = ({ title, className }: Props): ReactElement => {
	return <h1 className={"text-3xl font-bold " + className}>{title}</h1>;
};

export default Heading1;

import React, { ReactElement } from "react";

interface Props {
	isVisible: boolean;
}

const Loading = ({ isVisible }: Props): ReactElement => {
	return (
		<div
			className={
				"pointer-events-none absolute left-0 top-0 z-30 mx-auto flex h-screen w-screen items-center justify-center bg-white transition duration-700 " +
				(!isVisible ? "opacity-0" : "opacity-100")
			}
		>
			<i className="loading-wheel h-40 w-40 animate-spin-slow" />
		</div>
	);
};

export default Loading;

import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSelectOption from "./LanguageSelectOption";
import Overline from "./Overline";

interface Props {
	isMobileVisible: boolean;
	setMobileVisible: (visible: boolean) => void;
}

const LanguageSelect = ({ isMobileVisible, setMobileVisible }: Props): ReactElement => {
	const { i18n } = useTranslation();
	const [isModalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		document.body.addEventListener("click", onAnywhereClick, true);
	}, []);

	const updateLanguage = (lang: string): void => {
		i18n.changeLanguage(lang);
		if (isMobileVisible) {
			setMobileVisible(false);
		}
	};

	const onToggleClick = (): void => {
		setModalVisible(!isModalVisible);
	};

	const onAnywhereClick = (e: MouseEvent): void => {
		if (e.target instanceof HTMLElement && e.target.id !== "language-selector") {
			setModalVisible(false);
		}
	};

	return (
		<div
			className={
				"flex-column relative flex cursor-pointer select-none items-center justify-center p-3 pr-5 text-white/70 " +
				(isMobileVisible ? "mt-8" : "")
			}
		>
			<div className="flex items-center justify-center" id="language-selector" onClick={onToggleClick}>
				<i className={"icon-more pointer-events-none mr-1 mb-1 inline-block h-8 w-8 transition duration-300 "} />
				<Overline className="pointer-events-none">{i18n.language}</Overline>
			</div>

			{/* Modal */}
			<div
				className={
					"absolute right-[-30px] w-[142px] bg-text px-[16px] pt-[12px] text-white transition top-[74px] bottom-auto " +
					(isModalVisible
						? "opacity-1 pointer-events-auto translate-y-0 scale-100"
						: "pointer-events-none -translate-y-2 scale-[1.005] opacity-0")
				}
			>
				<LanguageSelectOption
					title="English"
					onClick={() => updateLanguage("en")}
					isSelected={i18n.language === "en"}
				/>
				<LanguageSelectOption title="Slovak" onClick={() => updateLanguage("sk")} isSelected={i18n.language === "sk"} />
				<LanguageSelectOption title="French" onClick={() => updateLanguage("fr")} isSelected={i18n.language === "fr"} />
				<LanguageSelectOption
					title="Russian"
					onClick={() => updateLanguage("ru")}
					isSelected={i18n.language === "ru"}
				/>
				<LanguageSelectOption title="Arabic" onClick={() => updateLanguage("ar")} isSelected={i18n.language === "ar"} />
			</div>
		</div>
	);
};

export default LanguageSelect;

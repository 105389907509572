import React, { ReactElement, ReactNode } from "react";

interface Props {
	children: ReactNode;
	className?: string;
}

const Paragraph2 = ({ children, className }: Props): ReactElement => {
	return <p className={"font-sen text-2xs font-normal " + className}>{children}</p>;
};

export default Paragraph2;
